const styles = {
    root: {
      width: "90%",
    },
    picker: {
      width: "100% !important",
      marginTop: "2rem",
    },
    addColor: {
      width: "100%",
      padding: "1rem",
      marginTop: "1rem",
      fontSize: "2rem",
    },
    colorInput: {
      width: "100%",
      height: "70px",
    },
  };

  export default styles