import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./styles/colorBoxStyles"
import { withStyles } from "@material-ui/styles";

class colorBox extends Component {
  constructor(props) {
    super(props);
    this.state = { copied: false };
    this.changeCopyState = this.changeCopyState.bind(this);
  }
  changeCopyState() {
    this.setState({ copied: true }, () => {
      setTimeout(() => this.setState({ copied: false }), 1500);
    });
  }
  render() {
    const { name, background, paletteId, id, showingFullPalette, classes } =
      this.props;
    const { copied } = this.state;
    return (
      <CopyToClipboard
        key={name}
        text={background}
        onCopy={this.changeCopyState}
      >
        <div style={{ background: background }} className={classes.colorBox}>
          <div
            className={classNames(classes.copyOverlay, {[classes.showOverlay]: copied })}
            style={{ background }}
          ></div>
          <div
          className={classNames(classes.copyMessage, {[classes.showMessage]: copied })}
          >
            <h1>copied!</h1>
            <p className={classes.copyText}>{background}</p>
          </div>
          <div>
            <div className={classes.boxContent}>
              <span className={classes.colorName}>{name}</span>
            </div>
            <button className={`${classes.copyButton} ${classes.copyText}`}>
              Copy
            </button>
          </div>
          {showingFullPalette && (
            <Link
              to={`/palette/${paletteId}/${id}`}
              onClick={(e) => e.stopPropagation()}
            >
              <span className={`${classes.seeMore} ${classes.copyText}`}>
                More
              </span>
            </Link>
          )}
        </div>
      </CopyToClipboard>
    );
  }
}

export default withStyles(styles)(colorBox);
