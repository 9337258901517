export default {
    PaletteFooter: {
      backgroundColor: "#fff",
      height: "4vh",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      fontWeight: "bold",
    },
    emoji: {
      fontSize: "1.5rem",
      margin: "0 1rem",
    },
  };